import request from "@/utils/request";
const qs = require("qs");

/**
 * @return Promise
 */
export function getProductList(params) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code-management/product-code/product",
    method: "get",
    params: params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
}

/**
 * @return Promise
 */
export function putProductList(productCodes) {
  const data = {
    updateProductCodes: productCodes,
  };
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code-management/product-code/product",
    method: "put",
    data,
  });
}

/**
 * @return Promise
 */
export function getProductDetail(productCode) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code-management/product-code/product/detail",
    method: "get",
    params: {
      productCode: productCode,
    },
  });
}

/**
 * @return Promise
 */
export function postProductCode(data) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code-management/product-code/product/detail",
    method: "post",
    data: data,
  });
}

/**
 * @return Promise
 */
export function putProductCode(productCode, data) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: `/code-management/product-code/product/detail/${productCode}`,
    method: "put",
    data: data,
  });
}

/**
 * @return Promise
 */
export function deleteProductCode(productCode) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: `/code-management/product-code/product/detail/${productCode}`,
    method: "delete",
  });
}
